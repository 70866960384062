import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { submitTopic, submitPost, start_siofu_listen, fileServer, editPost } from './connection'
//import Script from 'react-load-script';

class CourseList extends Component{
    render(){
        return(
            <div>
                {this.props.courses.map((course, ind)=>{
                    return (
                        <Jumbotron key={ind} onClick={()=>{
                            this.props.changeStage('topics', course.id, course.name);
                        }}>
                            <h3>{course.name}</h3>
                        </Jumbotron>
                    );
                })}
            </div>
        );
    }
}

class NewTopic extends Component{
    constructor(props){
        super(props);
        this.state = {extended: false};
    }
    extend(){
        this.setState( {extended: !this.state.extended} );
    }
    submit(e){
        e.preventDefault();
        submitTopic(
            this.props.course_id,
            document.getElementById('title').value,
            document.getElementById('content').value
        )
        this.extend();
    }
    render(){
        return (
            this.state.extended ?
            <Jumbotron>
                <form id="topicForm" onSubmit={this.submit.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="title">Заглавие</label>
                        <input type="text" className='form-control' id='title' placeholder="Заглавие" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Съдържание</label>
                        <textarea className='form-control' id="content" form="topicForm" rows={5}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Създай тема</button>
                    <button type='button' className='btn btn-primary' onClick={this.extend.bind(this)} style={{float: 'right'}}>Затвори</button>
                </form>
            </Jumbotron> :
            <div><button onClick={this.extend.bind(this)} className="btn btn-primary">Добави нова тема</button></div>
        );
    }
}

class TopicList extends Component{
    getTopics(){
        let ans = [];
        let n = this.props.topics.length;
        for (let i=0; i<n; i+=1){
            ans[i/1] = (
                <div key={i/1} className="row">
                    <div className='col-md-12' key={i}>
                        <Jumbotron onClick={()=>{
                            this.props.changeStage('posts', this.props.topics[i].id, this.props.topics[i].title);
                        }}>
                            <h3>{this.props.topics[i].title}</h3>
                            <pre>{this.props.topics[i].content}</pre>
                        </Jumbotron>
                    </div>
                </div>
            );
        }
        return ans;
    }
    render(){
        return (
            <div>
                <button className="btn btn-primary" style={{marginBottom: "40px"}} onClick={()=>{
                    this.props.changeStage('courses', 0);
                }}>Обратно към списък с курсове</button>
                <h3 style={{float: "right", display: "inline", marginBottom: "20px"}}>{this.props.heading}</h3>
                {/*this.props.topics.map((topic, index)=>{
                    return (
                        <Jumbotron key={index} onClick={()=>{
                            this.props.changeStage('posts', topic.id);
                        }}>
                            <h3>{topic.title}</h3>
                            <pre>{topic.content}</pre>
                        </Jumbotron>
                    );
                })*/
                this.getTopics()}
                {this.props.userInfo.permission ? 
                <NewTopic course_id={this.props.course_id}/> :
                null}
            </div>
        );
    }
}

/*function format(date){
    let d = new Date(date);
    let monthName = ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"];
    return d.getDate() + " " + monthName[d.getMonth()] + " " + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
}*/

class NewPost extends Component{
    constructor(props){
        super(props);
        this.state = {extended: false, files: []};
    }
    extend(){
        if (!this.state.extended){
            setTimeout(()=>{
                start_siofu_listen('file'+this.props.reply_id, (name, link)=>{
                    console.log(name, link);
                    this.setState({extended: this.state.extended, files: this.state.files.concat([{name: name, link: link}])});
                });
            }, 200)
        }
        this.setState( {extended: !this.state.extended, files: []} );
    }
    submit(e){
        e.preventDefault();
        submitPost(
            this.props.topic_id,
            this.props.to_id,
            this.props.reply_id,
            document.getElementById('title'+this.props.reply_id).value,
            document.getElementById('content'+this.props.reply_id).value,
            this.state.files
        )
        this.extend();
    }
    render(){
        return (
            this.state.extended ?
            <Jumbotron>
                <form id="topicForm" onSubmit={this.submit.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="title">Заглавие</label>
                        <input type="text" className='form-control' id={'title'+this.props.reply_id} defaultValue={this.props.reply_id!==0 ? "Re: " + this.props.title : ""} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Съдържание</label>
                        <textarea className='form-control' id={"content"+this.props.reply_id} form="topicForm" rows={5}/>
                    </div>
                    {this.state.files.map((file, ind) => {
                        return <p key={ind}><a target="_blank" rel="noopener noreferrer" href={fileServer+"/"+file.link}>{file.name}</a></p>
                    })}
                    <div className="form-group">
                        <input type="file" className='form-control' id={'file'+this.props.reply_id}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Създай коментар</button>
                    <button type='button' className='btn btn-primary' onClick={this.extend.bind(this)} style={{float: 'right'}}>Затвори</button>
                </form>
            </Jumbotron> :
            <div style={{marginBottom: "50px"}}><button onClick={this.extend.bind(this)} className="btn btn-primary">Добави отговор</button></div>
        );
    }
}

class Post extends Component{
    constructor(props){
        super(props);
        this.state = {edit: false}
    }
    changeEdit(){
        this.setState({edit: !this.state.edit});
    }
    submit(e){
        e.preventDefault();
        editPost(
            this.props.post.id,
            document.getElementById('title'+this.props.post.id).value,
            document.getElementById('content'+this.props.post.id).value,
        );
        this.changeEdit();
    }
    render(){
        return this.state.edit ?
            <Jumbotron>
                <form onSubmit={this.submit.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="title">Заглавие</label>
                        <input type="text" className='form-control' id={'title'+this.props.post.id} defaultValue={this.props.post.title} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Съдържание</label>
                        <textarea className='form-control' id={"content"+this.props.post.id} form="topicForm" rows={5} defaultValue={this.props.post.content}/>
                    </div>
                    {this.props.post.files.map((file, ind) => {
                        return <p key={ind}><a target="_blank" rel="noopener noreferrer" href={fileServer+"/"+file.link}>{file.name}</a></p>
                    })}
                    <button type="submit" className="btn btn-primary">Запази промените</button>
                    <button type='button' className='btn btn-primary' onClick={this.changeEdit.bind(this)} style={{float: 'right'}}>Затвори</button>
                </form>
            </Jumbotron> :
            <div>
                <Jumbotron>
                    <h3>{this.props.post.title}</h3>
                    <pre>{this.props.post.content}</pre>
                    {this.props.userInfo.permission ?
                        <p>{this.props.post.from_name}</p> :
                        null
                    }
                    {this.props.post.files.map((file, ind) => {
                        return <p key={ind}><a target="_blank" rel="noopener noreferrer" href={fileServer+"/"+file.link}>{file.name}</a></p>
                    })}
                    {this.props.userInfo.permission ?
                        <button className="btn btn-primary" onClick={this.changeEdit.bind(this)}>Edit</button> :
                        null
                    }
                </Jumbotron>
                <div style={{marginLeft: "50px"}}>
                    {this.props.post.subposts.map((post, index)=>{
                        return <Post key={index} post={post} userInfo={this.props.userInfo}/>
                    })}
                    <NewPost topic_id={this.props.post.topic_id} to_id={this.props.post.from_id} reply_id={this.props.post.id} title={this.props.post.title}/>
                </div>
            </div>
    }
}

class PostList extends Component{
    render(){
        return (
            <div>
                <button className="btn btn-primary" style={{marginBottom: "40px"}} onClick={()=>{
                    this.props.changeStage('topics', this.props.back_id);
                }}>Обратно към списък с теми</button>
                <h3 style={{float: "right", display: "inline", marginBottom: "20px"}}>{this.props.heading}</h3>
                {this.props.posts.map((post, index)=>{
                    return <Post key={index} post={post} userInfo={this.props.userInfo}/>
                })}
                {this.props.userInfo.permission ? 
                <NewPost topic_id={this.props.topic_id} to_id={0} reply_id={0}/> :
                null}
            </div>
        );
    }
}

function insSubpost(posts, p){
    for (let p2 of posts){
        if (p2.id === p.reply){
            p2.subposts.push(p);
            p2.subposts[p2.subposts.length-1].subposts = [];
            return true;
        }
        if( insSubpost(p2.subposts, p) ) return true;
    }
    return false;
}

function createPosts(posts){
    let ans = [];
    for (let p of posts){
        if (p.reply === 0) {
            ans.push(p)
            ans[ans.length-1].subposts = [];
        }else{
            insSubpost(ans, p);
        }
    }
    //console.log(ans);
    return ans;
}

export { CourseList, TopicList, PostList, createPosts }
