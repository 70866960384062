import React, { Component } from 'react';
import './App.css';
import { Jumbotron } from 'react-bootstrap'
import { login, changePass } from './connection';

class LoginForm extends Component{
    login(e){
        e.preventDefault();
        login(
            document.getElementById('username').value,
            document.getElementById('password').value,
            (success, id, name, permission) => {
                if (!success) alert("Wrong username or password");
                else {
                    alert("Logged in as "+name);
                    this.props.changeStage("courses", 0);
                    this.props.updateUserInfo(id, name, permission);
                }
            }
        )
    }
    render(){
        return(
            <Jumbotron>
                <h3>Log in</h3>
                <form onSubmit={this.login.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="username">Email (username)</label>
                        <input type="email" className='form-control' id='username' placeholder="Email" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className='form-control' id='password' required/>
                    </div>
                    <button type="submit" className="btn btn-primary">Log in</button>
                </form>
            </Jumbotron>
        );
    }
}

class ChangePassword extends Component{
    change(e){
        e.preventDefault();
        if (document.getElementById('npassword1').value !== document.getElementById('npassword2').value) alert("Паролите не съвпадат.");
        else
            changePass(
                document.getElementById('username').value,
                document.getElementById('password').value,
                document.getElementById('npassword1').value,
                (success) => {
                    if (!success) alert("Wrong username or password");
                    else {
                        alert("Паролата е сменена успешно!");
                    }
                }
            );
    }
    render(){
        return (
            <Jumbotron>
                <h3>Change password</h3>
                <form onSubmit={this.change.bind(this)}>
                    <div className="form-group">
                        <label htmlFor="username">Email (username)</label>
                        <input type="email" className='form-control' id='username' placeholder="Email" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className='form-control' id='password' required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="npassword1">New password</label>
                        <input type="password" className='form-control' id='npassword1' required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="npassword2">Repeat new password</label>
                        <input type="password" className='form-control' id='npassword2' required/>
                    </div>
                    <button type="submit" className="btn btn-primary">Change password</button>
                </form>
            </Jumbotron>
        );
    }
}

export { LoginForm, ChangePassword }