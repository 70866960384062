import openSocket from 'socket.io-client';
import SocketIOFileUpload from "./client.js";

const fileServer = "http://35.181.151.137:8083";
const socket = openSocket();
var siofu = new SocketIOFileUpload(socket);

/*import("/siofu/client.js").then(module => {
    console.log('stuff');
    console.log(module);
});*/

function ctp(cb, cbc, cbt, cbp, cbup){
    socket.on("ctp", (courses, topics, posts)=>{
        cb(courses, topics, posts);
    });

    socket.on("add_t", (topic)=>{
        cbt(topic);
    });
    
    socket.on("add_p", (post)=>{
        cbp(post);
    });

    socket.on("upd_p", (post)=>{
        cbup(post);
    })
}

function submitTopic(course_id, title, content){
    socket.emit("add_t", course_id, title, content);
}

function submitPost(topic_id, to_id, reply, title, content, files){
    socket.emit("add_p", topic_id, to_id, reply, title, content, files);
}

function editPost(id, title, content){
    socket.emit("upd_p", id, title, content);
}

function login(user, pass, cb){
    socket.emit("login", user, pass);
    socket.on("sc", (success, id, name, permission) => {
        cb(success, id, name, permission);
    });
}

function changePass(user, pass, npass, cb){
    socket.emit("change", user, pass, npass);
    socket.on("scch", (success)=>{
        cb(success);
    });
}

var next_cb;

socket.on('file_uploaded', (name, link)=>{
    next_cb(name, link);
})

function start_siofu_listen(id, cb){
    siofu.listenOnInput(document.getElementById(id));

    siofu.addEventListener("complete", function(event){
        next_cb = cb;
        //console.log(event.success);
        //console.log(event.file);
    });
}

export{ ctp, login, changePass, submitTopic, submitPost, start_siofu_listen, fileServer, editPost }