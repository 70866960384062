import "core-js/stable";
import React, { Component } from 'react';
import './App.css';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LoginForm, ChangePassword } from './login';
import { ctp } from './connection';
import { CourseList, TopicList, PostList, createPosts } from './posts'

class App extends Component {
  constructor(props){
    super(props);
    this.state = {userInfo: {id: -1, name: "", permission: false}, activeKey: 1, stage: "login", id: 0, back_id: 0, courses: [], topics: [], posts: []};
    ctp(
      (courses, topics, posts) => {
        this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: courses, topics: topics, posts: posts, heading: ""});
      },
      (course) => {
        let courses = this.state.courses;
        courses.push(course); 
        this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: courses, topics: this.state.topics, posts: this.state.posts, heading: this.state.heading});
      },
      (topic) => {
        let topics = this.state.topics;
        topics.push(topic);  // todo: must add check
        this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: this.state.courses, topics: topics, posts: this.state.posts, heading: this.state.heading});
      },
      (post) => {
        let posts = this.state.posts;
        posts.push(post);  // todo: must add check
        this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: this.state.courses, topics: this.state.topics, posts: posts, heading: this.state.heading});
      },
      (post) => {
        let ind = this.state.posts.findIndex((x)=>{return x.id===post.id});
        let posts = this.state.posts;
        posts[ind] = post;
        this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: this.state.courses, topics: this.state.topics, posts: posts, heading: this.state.heading});
      }
    );
  }
  changeStage(stage, id, heading){
    this.setState({userInfo: this.state.userInfo, activeKey: this.state.activeKey, stage: stage, id: id, back_id:this.state.id, courses: this.state.courses, topics: this.state.topics, posts: this.state.posts, heading: heading});
  }
  showStage(){
    if (this.state.stage === "login"){
      return <LoginForm changeStage={this.changeStage.bind(this)} updateUserInfo={this.updateUserInfo.bind(this)}/>
    }
    if (this.state.stage === "courses"){
      return <CourseList courses={this.state.courses} changeStage={this.changeStage.bind(this)}/>
    }
    if (this.state.stage === "topics"){
      return <TopicList topics={this.state.topics.filter(topic => topic.course_id === this.state.id)} changeStage={this.changeStage.bind(this)} back_id={this.state.back_id} userInfo={this.state.userInfo} course_id={this.state.id} heading={this.state.heading}/>
    }
    if (this.state.stage === "posts"){
      return <PostList posts={createPosts(this.state.posts.filter(post => post.topic_id === this.state.id))} changeStage={this.changeStage.bind(this)} back_id={this.state.back_id} userInfo={this.state.userInfo} topic_id={this.state.id} heading={this.state.heading}/>
    }
  }
  handleSelect(key){
    this.setState({userInfo: this.state.userInfo, activeKey: key, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: this.state.courses, topics: this.state.topics, posts: this.state.posts, heading: this.state.heading});
  }
  updateUserInfo(id, name, permission){
    this.setState({userInfo: {id: id, name: name, permission: permission}, activeKey: this.state.activeKey, stage: this.state.stage, id: this.state.id, back_id: this.state.back_id, courses: this.state.courses, topics: this.state.topics, posts: this.state.posts, heading: this.state.heading});
  }
  render() {
    return (
      <div>
        <Navbar inverse collapseOnSelect onSelect={this.handleSelect.bind(this)}>
          <Navbar.Header>
            <Navbar.Brand>
              <a href="#brand">SIcademy</a>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav activeKey={this.state.activeKey}>
              <NavItem eventKey={1}>
                Учебен форум
              </NavItem>
              <NavItem eventKey={2} href="#">
                Смяна на парола
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="container">
          {
            this.state.activeKey === 1 ?
            this.showStage() :
            <ChangePassword/>
          }
        </div>
      </div>
    );
  }
}

export default App;
